import React from "react";
import { useTheme } from "@mui/material/styles"; // Material-UI theme
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography, Grid } from "@mui/material";
// import partnerBg from "../../images/backgroung/PartnerBg.jpg";
import hettichLogo from "../../images/partners/HetichLogo.png";
import greenplyLogo from "../../images/partners/greenPlylogo.jpeg";
import centuryplyLogo from "../../images/partners/centuryPly.png";
import royaltoucheLogo from "../../images/partners/Royal-Touch.jpg";
import varmaLogo from "../../images/partners/VamaLogo.png";

const partners = [
  {
    name: "Hettich",
    logo: hettichLogo,
  },
  { name: "Greenply", logo: greenplyLogo },
  { name: "Century Ply", logo: centuryplyLogo },
  { name: "Royal Touche", logo: royaltoucheLogo },
  { name: "Varma & Associates", logo: varmaLogo },
];

const Partners: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        sx={
          {
            // // backgroundImage: `url(${partnerBg})`, // Correct way to reference the image
            // backgroundPosition: "center center",
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // display: "inline-block",
          }
        }
      >
        {/* <Box> */}
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            padding: "20px",
            // width: "100vw",
            // height: "50vh",
            mt: "80px",
            color: "black",
            textAlign: "center",
          }}
        >
          Our Partners
        </Typography>
        {/* </Box> */}
      </Box>

      <Typography
        variant="body1"
        sx={{
          color: "#555",
          marginBottom: "30px",
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          lineHeight: 1.6,
          fontSize: isMobile ? "1rem" : "1.2rem",
          padding: "10px",
          textAlign: "center",
        }}
      >
        We’re proud to collaborate with these renowned brands known for their
        excellence in interior design and quality materials.
      </Typography>

      <Grid
        container
        spacing={isMobile ? 2 : 4}
        justifyContent="center"
        alignItems="center"
      >
        {partners.map((partner, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                textAlign: "center",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <img
                src={partner.logo}
                alt={partner.name}
                style={{
                  width: "100%",
                  height: "150px",
                  objectFit: "contain",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: 600, color: "#333" }}>
                {partner.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Partners;
